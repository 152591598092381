const modalStore = {  
    namespaced: true,
    state: () => ({
      URL:'',
      isVisible: false,
      accessToken: '',
      nombreUser: '',
      sessionActive: false,
    }),

    mutations: {
      setURL (state, url) {
        state.URL = url
      },

      setIsVisible (state, visible) {
        state.isVisible = visible
      },

      setAccessToken (state, token) {
        state.accessToken = token
      },

      setNombreUser (state, nombreUser) {
        state.nombreUser = nombreUser
      },

      setSessionActive (state, session) {
        state.sessionActive = session
      },
    },

    actions: {

    },

    getters: {
      getURL: state => {
        return state.URL;
      },

      getIsVisible: state => {
        return state.isVisible;
      },

      getAccessToken: state => {
        return state.accessToken;
      },

      getNombreUser: state => {
        return state.nombreUser;
      },

      getSessionActive: state => {
        return state.sessionActive;
      },

    }
};

export default modalStore

