const miscellaneousStore = {  
    namespaced: true,
    state: () => ({
        isMobile: '',
        club: ''
    }),

    mutations: {
      setDevice (state, value) {
        state.isMobile = value
      },
      setSelectClub (state, value) {
        state.club = value
      },
    },

    actions: {
      detectDevice ({ commit }) {
        if (process.browser) {
          if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          commit('setDevice', true)
          } else {
            commit('setDevice', false)
          }
        }
      }
    },

    getters: {
      getSelectClub: state => {
        return state.club;
      }
    }


};

export default miscellaneousStore

