<template>
    <div class="cart-dimer" :class="{ bloqueo : showCart}" @click="cerrarCart"/>
    <div class="section__appcart" :class="{ open : showCart}">
        <div class="section__appcart-header border-bottom-0">
            <p>Carrito</p>
             <font-awesome-icon icon="fa-rectangle-xmark" @click="cerrarCart"/>
        </div>

        <div class="section__appcart-body">
            <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary"  role="status" v-if="$store.state.cart.loadProductSpiner">
                    <span class="visually-hidden">Cargando...</span>
                </div>    
            </div>
            
            <div class="section__appcart-products" v-for="product in productsCart" :key="product.id">
                <RouterLink :to="{ name: 'AppViewPublicacion', params: { publicacionSlug: product.slug } }">
                    <img
                        class="section__appcart-products-img img-thumbnail"
                        :src="`/storage/${product.imagen}`"
                        :alt="product.titulo"
                        @click="cerrarCart()"
                    >
                  </RouterLink>
                <div class="section__appcart-info">
                    <RouterLink :to="{ name: 'AppViewPublicacion', params: { publicacionSlug: product.slug } }">
                        <p @click="cerrarCart()">{{ product.titulo }}</p>
                    </RouterLink>
                    <div class="section__appcart-info-precio">
                        <p> $ {{ formatPrice(product.precio) }}</p>
                        <div class="section__appcart-info-precio-cantidad">
                            <button class="btn btn-primary btn-sm" @click="decrementarProducto(product.id)">-</button>
                            <p>{{ product.cantidad }}</p>
                            <button class="btn btn-primary btn-sm" @click="incrementarProducto(product.id, product.stock, product.maximoEnvio)" :disabled="(product.cantidad >= product.stock) || (product.maximoEnvio > 0 && product.cantidad >= product.maximoEnvio)">+</button>
                        </div>
                    </div>
                    <p class="bg-warning mt-1" v-if="product.maximoEnvio > 0 && product.cantidad >= product.maximoEnvio">Maximo ({{ product.maximoEnvio }}) productos (venta)</p>
                </div>
            </div>
        </div>
        
          <div class="section__appcart-footer" v-if="productsCart.length > 0 && !this.$store.state.cart.loadProductSpiner">
            <p>SubTotal: $ {{ formatPrice($store.state.cart.totalParcial) }} </p>
            <RouterLink :to="{ name:'cart'}">
              <button class="btn btn-primary w-100" @click="cerrarCart()">Ir al carrito</button>
           </RouterLink>
        </div>
        <div v-if="productsCart.length <= 0 && !this.$store.state.cart.loadProductSpiner">
            <p class="text-center pb-4">No tienes productos en el carrito</p>
        </div>
    </div>   
</template>
<script>


import { mapActions } from 'vuex'
import store from './../../store'

import { cantidadElementosNORepetidoLocalStore, PRODUCTS_KEY, KEY_LOCALSTORA, KEY_FECHALOCALSTORA } from './../../utils/cart/cart.js' 
// import { PRODUCTS_KEY, KEY_LOCALSTORA, KEY_FECHALOCALSTORA } from './../utils/cart/cart.js'
import { agregarProductoCartStorage, eliminarProductoCartStorage,incrementarItemProducto, decrementarItemProducto } from './../../utils/cart/cart.js'
export default {
    name: 'Cart',
    store,
    computed: {
        showCart() {
            return this.$store.state.cart.showCart
        },
        productsCart() {
             return this.$store.state.cart.productsCart
        },
        disabled () {
        if( this.$store.state.cart.productsCart.cantidad > this.$store.state.cart.productsCart.stock) {
            return true
        }
        return false
        },
        statusAddProductStore() {
            this.$store.state.cart.statusAddProductStore
        }
    },
    methods: {
        ...mapActions('cart', {
            productCartApi: 'productCartApi',
        }),
        cerrarCart() {
            this.$store.commit('cart/setShowCart', false)
            this.$store.commit('cart/setLoadProductSpiner', true)
        },
        incrementarProducto(idProduct, stock, maximoEnvio) {
            agregarProductoCartStorage(idProduct, stock, maximoEnvio)
            incrementarItemProducto(idProduct,  this.$store.state.cart.productsCart)
            this.totalizar()
        },
        decrementarProducto(idProduct, stock) {
            eliminarProductoCartStorage(idProduct, stock)
            decrementarItemProducto(idProduct,  this.$store.state.cart.productsCart)
            this.totalizar()
        },
        totalizar() {
            let totalTemp = 0;
            this.$store.state.cart.productsCart.forEach(product => {
                totalTemp += product.precio * product.cantidad
            });

            this.$store.commit('cart/setTotalParcial', Number(totalTemp).toFixed(2))
        },
        formatPrice(number) {
          if(number) {
            return number.toString().split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          }
          return 0;
        },

        verificarLocalStorage() {
            const fechaLocalStorage = localStorage.getItem(KEY_FECHALOCALSTORA);
            if (fechaLocalStorage) {
                const fechaCreacionLocalStorage = new Date(fechaLocalStorage);
                const fechaActual = new Date();
                const diferenciaEnMilisegundos = fechaActual - fechaCreacionLocalStorage;
                //limpiar localstorage  si tiene mas de un día
                // const diasTranscurridos = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);
                const horasTranscurridas = diferenciaEnMilisegundos / (1000 * 60 * 60)
                //limpiar localstorage  si tiene mas de a hora
                if (horasTranscurridas > 1) {
                    localStorage.removeItem(PRODUCTS_KEY)
                    localStorage.removeItem(KEY_LOCALSTORA)
                    localStorage.removeItem(KEY_LOCALSTORA)
                    this.$store.commit('cart/setCantidadProducto', 0)
                }
            }
        }
    },
    watch: {
        showCart() {
            if(this.$store.state.cart.showCart) {
                this.verificarLocalStorage()
                setTimeout(() => {
                    this.productCartApi('cart')    
                }, 500);
                

                //  const cantidadProducto = cantidadElementosNORepetidoLocalStore()
                //  if(cantidadProducto <= 0) {
                //     this.$store.commit('cart/setCantidadProducto', '')
                //  }

            }
        },
        statusAddProductStore() {
            this.totalizar()
        }
    }
}
</script>
<style lang="scss" scoped>

    .cart-dimer {
        opacity: 0;
        transition: opacity 0.5s ease;;
    }

    .bloqueo {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 1000;
    }

    .open {
      display: flex;
    }

    .button {
        padding: 4px 10px;
    }
</style>
