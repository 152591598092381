
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
      path: '/',  
      name: 'app-home',
      component: () => import('./../views/AppHome.vue'),
    },
    {
      path:'/:categoria',
      name:"AppViewContentPrincipal",
      component: () => import('./../views/AppViewContentPrincipal.vue'),
      props: true, 
      children: [
        {
          path:':subcategoria',
          name:'AppViewPublicaciones',
          component: () => import('./../views/AppViewPublicaciones.vue'),
          props: true, 
        }
      ]
    },
    {
      path:'/publicacion/:publicacionSlug',
      name:"AppViewPublicacion",
     component: () => import('./../views/AppViewPublicacion.vue'),
      props: true, 
    },
    {
      path:'/buscar/:textBusqueda',
      name:"AppViewBusqueda",
      component: () => import('./../views/AppViewBusqueda.vue'),
      props: true, 
    },
    {
      path:'/mapa-de-beneficios',
      name:"mapa-de-beneficios",
      component: () => import('./../views/AppViewMapaBeneficios.vue')
    },
    {
      path:'/casa-club',
      name:"casa-club",
      component: () => import('./../views/AppViiewCasaClub.vue')
    },
    {
      path:'/dudas-consultas',
      name:"dudas-consultas",
      component: () => import('./../views/AppViiewDudaConsulta.vue')
    },
    // {
    //   path:'/revista',
    //   name:"revista",
    //   component: () => import('./../views/AppViewRevista.vue')
    // },
    {
      path:'/terminos-y-condiciones',
      name:"terminos-y-condiciones",
      component: () => import('./../views/AppViewTerminosycondiciones.vue')
    },
    {
      path:'/cart',
      name:"cart",
      component: () => import('./../views/AppViewCart.vue')
    },
    {
      path:'/pedido',
      name:"pedido",
      component: () => import('./../views/AppViewPedido.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'pagenotfound',
      component: () => import('./../views/AppHome.vue'),
    }
       
]

const router = createRouter({
history: createWebHistory(),
  routes,
})

export default router