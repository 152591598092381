import { createStore } from 'vuex'
import publicacionesStore from './modules/publicaciones'
import modalStore from './modules/modal'
import miscellaneousStore from './modules/miscellaneous'
import cartStore from './modules/cart'


export default createStore({
  modules: {
    publicacion: publicacionesStore,
    modal: modalStore,
    miscellaneous: miscellaneousStore,
    cart: cartStore
  }
})
