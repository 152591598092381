import axios from 'axios';
import { getProductsCartStorage } from './../../utils/cart/cart.js'

const cartStore = {  
    namespaced: true,
    state: () => ({
      showCart: false,
      productsCart: [],
      loadProductSpiner: true,
      loadProductSpinerViewCart: true,
      loadProductSpinerPedido: true,
      statusAddProductStore: false,
      keyLocalStarage: '',
      cantidadProducto: 0,

      totalDomicilio: 0,
      zonaDomicilio: '',
      comunaDomicilio: '',
      direccionDomicilio: '',
      totalParcial: 0,
      total: 0,
      

      datosUsuario: {
        rutUsuario: '',
        nombre: null,
        correo: null,
        correoConfirm: null,
        // rut: null,
        telefono: null,
        emolId: null
      },

      datosFacturacion: {
          razon: '',
          rut: '',
          giro: '',
          direccionA: '',
          direccionB: '',
          comuna: '',
          region: '',
      },

      datosDespacho: {
        contacto: '',
        telefono: '',
        // email: '',
        region: '',
        comuna: '',
        direccion: '',
        direccionA: '',
        direccionB: '',
        notaPedido: '',
        // montoDespacho: '',
        // despacho: false
      },
    }),

    mutations: {
      setShowCart (state, value) {
        state.showCart = value
      },
      setLoadProductSpiner (state, value) {
        state.loadProductSpiner = value
      },
      loadProductSpinerViewCart (state, value) {
        state.loadProductSpiner = value
      },
      setStatusAddProductStore (state, value) {
        state.statusAddProductStore = value
      },
      setZonaDomicilio (state, value) {
        state.zonaDomicilio = value
      },
      setComunaDomicilio (state, value) {
        state.comunaDomicilio = value
      },
      setTotalDomicilio (state, value) {
        state.totalDomicilio = value
      },
      setTotalParcial (state, value) {
        state.totalParcial = value
      },
      setTotal (state, value) {
        state.total = value
      },
      setKeyLocalStarage (state, value) {
        state.keyLocalStarage = value
      },
      setCantidadProducto (state, value) {
        state.cantidadProducto = value
      }
      
    },

    actions: {
       productCartApi: async ({ state, getters, rootState }, paramsCall) => {
        const productos = getProductsCartStorage()
        
        const frecuenciaProductos = new Map();
        productos.forEach(numero => {
          frecuenciaProductos.set(numero, (frecuenciaProductos.get(numero) || 0) + 1);
        });

        const productosUnificados = [...frecuenciaProductos.entries()].map(([numero, frecuencia]) => ({
          numero,
          frecuencia
        }));
        
       const productsCart = []
       const axiosrequestArray = []
      //  let valor = 1999
        productosUnificados.forEach(element => {
          const axiosrequest = axios.get(`/api/publicacion-api/${element.numero}`)
          axiosrequestArray.push(axiosrequest)
        });
        
        state.productsCart = []

        if(paramsCall == 'cart') {
          state.loadProductSpiner = true
        }else if(paramsCall === 'viewCart')  {
          state.loadProductSpinerViewCart = true
        }else if(paramsCall === 'pedido') {
          state.loadProductSpinerPedido  = true
        }

        // let intentos = 0
        // const maxIntentos = 3
      
        // while (intentos < maxIntentos) {
          try {
              await axios.all(axiosrequestArray).then(axios.spread(function(...responses) {
                for (let i = 0; i < responses.length; i++) {
                  //console.log(responses[i].data)
                  if (responses[i].data) {
                    const descripcionMonto = rootState.modal.sessionActive ? 'Precio Socio' : 'Precio General'
                    const elemento = responses[i].data[0].beneficios.find(element => element.descripcion_monto === descripcionMonto)
                    const monto = elemento ? elemento.monto ?? 0 : 0
                
                    state.productsCart.push({
                      id: responses[i].data[0].id,
                      titulo: responses[i].data[0].titulo,
                      bajada: responses[i].data[0].bajada,
                      slug: responses[i].data[0].slug,
                      imagen: responses[i].data[0].imagen_principal,
                      socio: rootState.modal.sessionActive ? 'Socio' : 'General',
                      precio: monto,
                      cantidad: 0,
                      stock: responses[i].data[0].stock,
                      montoPesoDimension: responses[i].data[0].monto_peso_dimension,
                      maximoEnvio: responses[i].data[0].maximo_envio, 
                    })
                      
                  }
                }
              }))
              // intentos = maxIntentos + 10
            } catch (error) {
            //   intentos++;
            //   setTimeout(() => {
                
            //   }, 5000);
            }
        
        // }

        if(paramsCall == 'cart') {
          state.loadProductSpiner = false
        }else if(paramsCall === 'viewCart')  {
          state.loadProductSpinerViewCart = false
        }else if(paramsCall === 'pedido') {
          state.loadProductSpinerPedido  = false
        }
       
         if(state.productsCart.length > 0) {
            let totalTemp = 0;
            productosUnificados.forEach(element => {
              const index = state.productsCart.findIndex( item => item.id == element.numero)
              if (index !== -1) {
                state.productsCart[index].cantidad = element.frecuencia;
                totalTemp += state.productsCart[index].precio * state.productsCart[index].cantidad
              }
            });
            state.totalParcial = Number(totalTemp).toFixed(2)
          }

       },
    },

    // getters: {
    //   getShowCart () {
    //     return state.showCart
    //   },
    //   getloadProductSpiner () {
    //     return state.loadProductSpiner
    //   },
   
    // }
};

export default cartStore

