export const  PRODUCTS_KEY = 'products'
export const  KEY_LOCALSTORA = 'key'
export const  KEY_FECHALOCALSTORA = 'keydate'

import { v4 as uuidv4 } from 'uuid';
import store from './../../store'

export function getProductsCartStorage() {
    const products = localStorage.getItem(PRODUCTS_KEY)

     if(!products) return []
     return JSON.parse(products)
 }

 export function agregarProductoCartStorage(idProducto, stock, maximoEnvio) {
      const products = getProductsCartStorage()
      const cantidad = products.filter(item => item == idProducto)
      
      if(stock > cantidad.length && (maximoEnvio == 0 || cantidad.length < maximoEnvio)) {
        products.push(idProducto)
        localStorage.setItem(PRODUCTS_KEY, JSON.stringify(products))
        store.commit('cart/setStatusAddProductStore', !store.state.cart.statusAddProductStore)
        
        if(products.length == 1) {
          const key = uuidv4()
          store.commit('cart/setKeyLocalStarage', key)
          localStorage.setItem(KEY_LOCALSTORA, key)
          localStorage.setItem(KEY_FECHALOCALSTORA, new Date().toString())
        }
        
      }
 }

 export function eliminarProductoCartStorage(idProducto) {
    const products = getProductsCartStorage()
    const index = products.indexOf(idProducto)
    if(index > -1) {
        products.splice(index, 1)
        localStorage.setItem(PRODUCTS_KEY, JSON.stringify(products))

        store.commit('cart/setStatusAddProductStore', !store.state.cart.statusAddProductStore)
    }
 }

 export function eliminarProductoCompletoCartStorage(idProducto, productsCart) {
  const productsStorage = getProductsCartStorage()
  const productResult =   productsStorage.filter(elemento => elemento !== idProducto)
  localStorage.setItem(PRODUCTS_KEY, JSON.stringify(productResult))

  const index = productsCart.findIndex( item => item.id == idProducto)
  productsCart.splice(index, 1) 

  store.commit('cart/setStatusAddProductStore', !store.state.cart.statusAddProductStore)
}

 export function incrementarItemProducto(idProduct, productsCart) {
    const index = productsCart.findIndex( item => item.id == idProduct)
      if (index !== -1) {
        productsCart[index].cantidad = productsCart[index].cantidad + 1; 
      }
      return productsCart;
 }

 export function decrementarItemProducto(idProduct, productsCart) {
    const index = productsCart.findIndex( item => item.id == idProduct)
    if (index !== -1) {
      productsCart[index].cantidad = productsCart[index].cantidad - 1; 
      if(productsCart[index].cantidad <= 0) {
        productsCart.splice(index, 1)  
      }
    }
      return productsCart;
}

export function  cantidadElementosNORepetidoLocalStore () {
  const products = getProductsCartStorage()
    
  const arraySinDuplicados = [...new Set(products)];
  const cantidadElementosUnicos = arraySinDuplicados.length;

  return cantidadElementosUnicos
}

export function eliminarTodoCartStorage() {
  localStorage.removeItem(PRODUCTS_KEY)
}