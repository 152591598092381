
import axios from 'axios';

const publicacionesStore = {  
    namespaced: true,
    state: () => ({
      URL:'',
      URL2:'https://clubmercuriovalpo.cl',
      categoria: '',
      categorias: [],
      subCategorias: [],
      datosCategoriaSubCategira: [],
      publicaciones: [],
      publicacionesDestacadas: [],
      publicacionesRelacionadas: [],
      publicidades: [],
      bannersHome: [],
    }),

    mutations: {
      setCategoria (state, categoria) {
        state.categoria = categoria
      },

      setCategorias (state, categorias) {
        state.categorias = categorias
      },

      setDatosCategoriaSubCategira (state, info) {
        state.datosCategoriaSubCategira = info
      },
 
      setSubCategorias (state, subCategorias) {
        state.subCategorias = subCategorias
      },

      setPublicacionesDestacadas (state, publicaciones) {
        state.publicacionesDestacadas = publicaciones
      },

      setPublicacionesRelacionadas (state, publicaciones) {
        state.publicacionesRelacionadas = publicaciones
      },

      setPublicidadesActivas (state, publicidades) {
        state.publicidades = publicidades
      },

      setPublicaciones (state, publicaciones) {
        state.publicaciones = publicaciones
      },

      setBannersHome (state, banners) {
        state.bannersHome = banners
      }

    },

    actions: {
      
      publicidadesActivas ({ commit, state }, categoria) {
        commit('setPublicidadesActivas', [])
       let publicidades = []
        const timeStamp = value => new Date(value).valueOf()
          try {
            axios.all(
              [
                axios
                  .get(`${state.URL}/api/publicidades-fija-api/${categoria}`),
                axios
                  .get(`${state.URL}/api/publicidades-temporal-api/${categoria}`)
              ])
              .then(axios.spread((publicidadFija, publicidadTemporal) => {
                publicidades = publicidadFija.data.concat(publicidadTemporal.data)
                publicidades = publicidades.sort((a,b) => timeStamp(b.updated_at) - timeStamp(a.updated_at))
                commit('setPublicidadesActivas', publicidades)
              }));
        } catch (error) {

        }
      },

      categorias({ commit, state  }) {
      try {
        axios
          .get(`${state.URL}/api/categorias-api`)
          .then((res) => {
              commit('setCategorias', res.data)
          });
        } catch (error) {
            console.log(error)
        }
      },
  
      subCategorias({ commit, state }, categoria) {
        try {
          axios
            .get(`${state.URL}/api/subCategorias-api/${categoria}`)
            .then((res) => {
              commit('setSubCategorias', res.data)
            });
        } catch (error) {
            console.log(error)
        }
      },

    // publicacionesDestacadas({ commit, state }, { categoriaSlug, size, page, session }) {
    //   let categoria = categoriaSlug == '' ||  categoriaSlug == null ? 1 : categoriaSlug
    //   try {
    //     axios
    //       .post(
    //         `${state.URL}/api/publicacionesdestacadas-api`, {"categoria": categoria,  'page': page, 'size': size, 'session': session}
    //       )
    //       .then((res) => {
    //         commit('setPublicacionesDestacadas', res.data)
    //       });
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
      publicacionesDestacadas({ commit, state }, { categoriaSlug, size, page, session }) {
      let categoria = categoriaSlug == '' ||  categoriaSlug == null ? 1 : categoriaSlug
        try {
          axios
            .get(
              // `${state.URL}/api/publicacionesdestacadas-api`, {"categoria": categoria,  'page': page, 'size': size, 'session': session}
              // `${state.URL}/api/publicacionesdestacadas-api?categoria=${categoria}&page=${page}&size=${size}&session=${session}`
              `${state.URL}/api/publicacionesdestacadas-api?categoria=${categoria}&page=${page}&size=${size}`
            )
            .then((res) => {
              commit('setPublicacionesDestacadas', res.data)
            });
        } catch (error) {
          console.log(error)
        }
      },
	
    publicacionesRelacionadas({ commit, state }, { categoriaSlug, size, page, session, publicacionId }) {
      let categoria = categoriaSlug == '' ||  categoriaSlug == null ? 1 : categoriaSlug
      try {
        axios
          .post(
            `${state.URL}/api/publicacionesrelacionadas-api`, {"categoria": categoria,  'page': page, 'size': size, 'session': session, 'publicacionId': publicacionId}
          )
          .then((res) => {
            commit('setPublicacionesRelacionadas', res.data)
       });
      } catch (error) {
        console.log(error)
      }
    },
    publicaciones({ commit, state }, { categoriaSlug, subCategoriaSlug,  size, page, session }) {
      let publicaciones = []
      try {
        axios
          .post(`${state.URL}/api/publicacionessubcategoias-api`, {"categoria": categoriaSlug, "subcategoria": subCategoriaSlug, 'page': page, 'size': size, 'session': session})
          .then( (res) => {
            publicaciones = res.data
            commit('setPublicaciones', publicaciones )
          });
      } catch (error) {

      }
    },
    informacionBasicaCategoriaSubCategoria ({ commit, state }, { categoriaSlug, subCategoriaSlug}) {
      let publicaciones = []
      try {
        axios
          .post(`${state.URL}/api/informacion-categoria-subcategoias-api`, {"categoria": categoriaSlug, "subcategoria": subCategoriaSlug})
          .then( (res) => {
            if(res.data) {
              commit('setDatosCategoriaSubCategira', res.data[0] ) 
            }
          });
      } catch (error) {

      }
    },

    bannersHome ({ commit, state }, categoria) {
      commit('setBannersHome', [])
     let bannersHome = []
      const timeStamp = value => new Date(value).valueOf()
        try {
          axios.all(
            [
              axios
                .get(`${state.URL}/api/banners-home-fijo-api`),
              axios
                .get(`${state.URL}/api/banners-home-temporal-api`)
            ])
            .then(axios.spread((bannerHomeFijo, bannerHomeTemporal) => {
              bannersHome = bannerHomeFijo.data.concat(bannerHomeTemporal.data)
              bannersHome = bannersHome.sort((a,b) => timeStamp(b.updated_at) - timeStamp(a.updated_at))
              commit('setBannersHome', bannersHome)
            }));
      } catch (error) {

      }
    },
    
    },
    getters: {
      getCategoria: state => {
        return state.categoria;
      },

      getCategorias: state => {
        return state.categorias;
      },

      getSubCategorias: state => {
        return state.subCategorias
      },

      getSubCategoriasisImg: state => {
        const subCategoriaImg = state.subCategorias.find(item => item.mostrar_icono == 1  && (item.icono !== '' || item.icono !== null || item.icono !== undefined) )
        return subCategoriaImg === undefined ? false : true
      },

      getDatosCategoriaSubCategira: state => {
        return state.datosCategoriaSubCategira
      },
   
      getPublicacionesDestacadas: state => {
        return state.publicacionesDestacadas
      },

      getPublicacionesRelacionadas: state => {
        return state.publicacionesRelacionadas
      },

      getPublicidadesActivas: state => {
        return state.publicidades
      },

      getPublicaciones:  state => {
        return state.publicaciones
      },

      getBannersHome:  state => {
        return state.bannersHome
      }
    }
};

export default publicacionesStore
